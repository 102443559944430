import { FC, useState } from 'react'
import { Carousel, Text } from 'src/ui/components'
import styles from './HeroBanner.module.scss'
import { RoomImageSlide } from 'src/core/Availability/domain/Availability.model'
import { getServiceImageLoader as imageLoader } from 'src/ui/utils/images'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { LandingHeader } from '../LandingHeader'
import { UserLevelV2 } from 'src/core/User/domain/User'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'

export type Props = {
  imageSlides?: RoomImageSlide[]
}

export const HeroBanner: FC<Props> = ({ imageSlides }) => {
  const [activeIndex, setActiveIndex] = useState('01')
  const { trans } = useTrans(['myb-lite-landing'])

  const onSlideChange = (index: number) => {
    setActiveIndex(`0${index + 1}`)
  }

  return (
    <>
      <div className={styles.container}>
        {isDefined(imageSlides) && (
          <Carousel
            className={styles.carousel}
            imageSlides={imageSlides}
            alt={trans('lite_landing-hero_banner-carousel_alt')}
            loader={imageLoader}
            hasToShowFullScreenButton={false}
            onSlideChange={onSlideChange}
          />
        )}
        {isDefined(imageSlides) && (
          <Flex
            alignItems="center"
            justifyContent="center"
            className={styles.numberWrapper}
          >
            <Text
              color="myBarcelo-dark"
              fontStyle="xl-700"
              family="barlowCondensed"
            >
              {activeIndex}
            </Text>
          </Flex>
        )}
      </div>
      <LandingHeader
        type={UserLevelV2.NotFound}
        title={trans('lite_landing-landing_header-title')}
        description={trans('lite_landing-landing_header-description')}
      />
    </>
  )
}
