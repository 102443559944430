import styles from './LandingHeader.module.scss'
import { Flex } from 'src/ui/styles/objects/Flex'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Text } from 'src/ui/components'
import { FC } from 'react'
import { UserLevelV2 } from 'src/core/User/domain/User'
import { classNames } from 'src/ui/utils/classnames'
import {
  MyBarceloBenefitsLogoVertical,
  MyBarceloItsAllYoursLogo,
} from 'src/ui/views/_components/MyBarceloBenefitsLogo'

type Props = {
  type: UserLevelV2
  levelName?: string
  title: string
  description: string
}
export const LandingHeader: FC<Props> = ({ type, title, description }) => {
  const color = type === UserLevelV2.Unique ? 'light' : 'dark'

  const renderLogoByLevel = (userLevel: UserLevelV2 | undefined) => {
    if (isUndefined(userLevel) || userLevel === UserLevelV2.NotFound) {
      return (
        <MyBarceloItsAllYoursLogo width={150} height={37} variant={color} />
      )
    }

    if (userLevel === UserLevelV2.Essential) {
      return (
        <MyBarceloBenefitsLogoVertical
          userLevel={UserLevelV2.Essential}
          size="large"
        />
      )
    }

    if (userLevel === UserLevelV2.Special) {
      return (
        <MyBarceloBenefitsLogoVertical
          userLevel={UserLevelV2.Special}
          size="large"
        />
      )
    }

    if (userLevel === UserLevelV2.Unique) {
      return (
        <MyBarceloBenefitsLogoVertical
          userLevel={UserLevelV2.Unique}
          variant="light"
          size="large"
        />
      )
    }
  }

  function getBannerStyles(type: UserLevelV2) {
    if (type === UserLevelV2.Essential) {
      return styles.essential
    }
    if (type === UserLevelV2.Special) {
      return styles.special
    }
    if (type === UserLevelV2.Unique) {
      return styles.unique
    }
    return styles.notFound
  }

  return (
    <Flex
      direction="column"
      justifyContent="space-evenly"
      className={classNames(styles.container, getBannerStyles(type))}
    >
      <Flex
        direction="row"
        gap="m"
        justifyContent="space-between"
        className="w-full"
      >
        {renderLogoByLevel(type)}
      </Flex>
      <Text
        fontStyle="3xl-700"
        as="h2"
        uppercase
        color={color}
        family="barlowCondensed"
        className="mt-l"
      >
        {title}
      </Text>
      <Text fontStyle="m-300" color={color} className="mt-m">
        {description}
      </Text>
    </Flex>
  )
}

export default LandingHeader
