import { FC } from 'react'
import { Flex } from 'src/ui/styles/objects/Flex'
import styles from './AllThisIsYours.module.scss'
import { Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Media } from 'src/ui/styles/objects/Media'
import { classNames } from 'src/ui/utils/classnames'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import {
  DatesIcon,
  DiamondIcon,
  DiscountIcon,
  DoubleCheckIcon,
} from 'src/ui/components/atoms/Icon/_icons/benefits'

export const AllThisIsYours: FC = () => {
  const { trans } = useTrans(['myb-lite-landing'])
  const languageConfig = useLanguageConfig()

  const isRTL = languageConfig.properties.isRTL

  const advantages = [
    {
      icon: DoubleCheckIcon,
      title: 'lite_landing_benefits_title-1',
      content: 'lite_landing_benefits_description-1',
    },
    {
      icon: DatesIcon,
      title: 'lite_landing_benefits_title-2',
      content: 'lite_landing_benefits_description-2',
    },
    {
      icon: DiscountIcon,
      title: 'lite_landing_benefits_title-3',
      content: 'lite_landing_benefits_description-3',
    },
    {
      icon: DiamondIcon,
      title: 'lite_landing_benefits_title-4',
      content: 'lite_landing_benefits_description-4',
    },
  ]

  return (
    <Flex
      direction="column"
      justifyContent="space-evenly"
      className={styles.container}
    >
      <Text
        className="mb-l mb-mobile-m"
        fontStyle={{ mobile: '2xl-700', tablet: '3xl-700' }}
        as="h2"
      >
        {trans('lite_landing-all_this_is_yours-title')}
      </Text>
      <Text
        className="mb-xl mb-mobile-2xl"
        fontStyle={{ mobile: 'm-300', tablet: 'l-300' }}
      >
        {trans('lite_landing-all_this_is_yours-content')}
      </Text>
      <div className={styles.advantagesContainer}>
        {advantages.map((advantage, index) => (
          <div key={index}>
            <Media tablet laptop desktop>
              <Flex direction="column" gap="m">
                <Flex direction="row" gap="m" alignItems="center">
                  <Icon
                    icon={advantage.icon}
                    size="xl"
                    viewBox="bigSquare"
                    color="icon-dark"
                  />
                  <Text fontStyle="xl-700">{trans(advantage.title)}</Text>
                </Flex>
                <Text fontStyle="l-300">{trans(advantage.content)}</Text>
              </Flex>
            </Media>
            <Media mobile>
              <Flex
                direction="column"
                alignItems="flex-start"
                gap="l"
                className={classNames(
                  styles.advantagesCard,
                  index === advantages.length - 1 &&
                    (isRTL ? styles.lastCardRtl : styles.lastCard),
                )}
              >
                <Icon
                  icon={advantage.icon}
                  size="xl"
                  viewBox="bigSquare"
                  color="icon-dark"
                />
                <Text fontStyle="l-700">{trans(advantage.title)}</Text>
                <Text fontStyle="m-300">{trans(advantage.content)}</Text>
              </Flex>
            </Media>
          </div>
        ))}
      </div>
    </Flex>
  )
}

export default AllThisIsYours
